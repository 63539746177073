<template>
  <AppLoading v-if="loading" />

  <main class="InvoiceDetailsView" v-if="!loading">
    <InvoicePage1 :invoice="invoice" page="1 / 2" />

    <InvoicePage2 :invoice="invoice" page="2 / 2" />
  </main>
</template>

<script>
  import AppLoading from '@/components/AppLoading.vue'
  import InvoicePage1 from '@/components/InvoicePage1.vue'
  import InvoicePage2 from '@/components/InvoicePage2.vue'

  import invoiceService from '@/services/invoices.service'
  import settingService from '@/services/settings.service'

  import { formatRelativeDate, formatDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'

  export default {
    components: {
      AppLoading,
      InvoicePage1,
      InvoicePage2
    },

    data() {
      return {
        loading: true,
        invoice: null
      }
    },

    watch: {
      '$route.params.id': {
        handler(id) {
          if (id) {
            this.load(id)
          }
        },
        immediate: true
      }
    },

    methods: {
      formatDate,
      formatRelativeDate,
      formatDecimal,

      async load(id) {
        this.loading = true

        this.invoice = await invoiceService.get(id)
        this.invoice.global_settings = await settingService.fetch()

        this.loading = false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .InvoiceDetailsView
    -webkit-print-color-adjust: exact
    font-size: .9em
</style>
